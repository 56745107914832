import React, {useState} from "react";
import "../scss/Main.scss";
import ProjectGallery from '../sections/ProjectGallery';
import Layout from '../components/Layout';

export default function Gallery({location}) {

    const [lightboxOn, setLightBox] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0); 

    return (
        <div className="gallery-container">
            <Layout index={galleryIndex} setIndex={setGalleryIndex} lightboxOn={lightboxOn} setLightBox={setLightBox} pageName={location.pathname}>
                <ProjectGallery title="PROJECT GALLERY" setIndex={setGalleryIndex} setLightBox={setLightBox}/>
            </Layout>
        </div>
    )
}
